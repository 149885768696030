
.carousel-h1 {
    font-size: 6vmin;
    font-weight: 700;
}

.carousel-h2 {
    font-size: max(3vmin, 0.75rem);
    font-weight: 400;
}

.carousel-dark {
    color: black;
}

.carousel-img {
    width: 100%;
    display: block;
}

.carousel-client-logo {
    margin: 0 1rem;
    vertical-align: bottom;
}

.carousel-caption-ct {
    position: absolute;
    right: 12%;
    top: 15vw;
    left: 12%;
    color: #fff;
    text-align: center;
}